<template>
  <div class="col-12">
    <select v-model="selected" class="mb-3 form-control custom-select">
      <optgroup v-for="(year) in Object.keys(options).reverse()" :label="year">
        <option v-for="month in options[year].reverse()" :value="year + '/' + month.id">
          {{month.label}}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
  import EventDate from '../utils/EventDate';
  import { MONTH_LABELS } from '../utils/EventDate';

  const defaultSelected = (index) => {
    const eventDate = new EventDate();

    const future = index.filter(value => {
      return value.year >= eventDate.year && value.month >= eventDate.month
    }).reverse();

    const selected = (future.length && future[0]) || index.slice(-1)[0];

    return selected ? [selected.year, selected.month].join("/") : null
  };

  export default {
    props: {
      index: {
        type: Array,
        required: true
      },
      onChange: {
        type: Function,
        required: false,
        default: function () {
        }
      }
    },
    data() {
      return {
        selected: defaultSelected(this.index)
      }
    },
    computed: {
      options() {
        return this.index
          /*.sort((a, b) => {
            if ((a.year - b.year) === 0) {
              return a.month - b.month;
            } else {
              return b.year - a.year
            }
          })*/
          .reduce((result, event) => {
            if (!result[event.year]) {
              result[event.year] = []
            }

            result[event.year].push({
              id: event.month,
              label: MONTH_LABELS[event.month]
            });
            return result
          }, {})
      }
    },
    mounted() {
      this.onChange(this.selected)
    },
    watch: {
      selected(newValue) {
        this.onChange(newValue)
      }
    }
  }
</script>
