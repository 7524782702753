<template>
  <div class="v-calendar">
    <!--@dayclick="goToEvent"-->
    <v-calendar :attributes='attributes'
                @update:fromPage='pageChanged'
                @update:toPage='pageChanged'
                is-expanded>
      <div slot='event'
           slot-scope='{ customData }'
           class='popover-row'
           :style="customData.labelStyle">
        <span class='popover-content'>
          <a :href='buildEventUrl(customData)'>{{ customData.event.title }}</a>
        </span>
        <span v-if="!customData.end">(od: {{ customData.start | moment('H:mm') }})</span>
      </div>
    </v-calendar>
  </div>
</template>

<script>
  import EventDate from '../utils/EventDate';
  import CalendarBean from './CalendarBean';
  import 'v-calendar/lib/v-calendar.min.css';
  import moment from 'moment';

  const eventsByDate = (a, b) => {
    return b.customData.start.getTime() - a.customData.start.getTime()
  };

  export default {
    data() {
      return {
        events: {}
      };
    },
    computed: {
      attributes() {
        return [
          // Today
          {
            contentStyle: {
              fontWeight: '800',
              color: '#114bad',
              borderColor: '#000',
              borderWidth: '2px',
              borderStyle: 'solid'
            },
            dates: new Date(),
          },
          // Attributes for todos
          ...(Object.values(this.events).sort(eventsByDate))
        ]
      }
    },
    mounted() {
      this.$nextTick(function () {
        const now = new EventDate();
        this.fetchEvents(now.year, now.month)
      })
    },
    methods: {
      pageChanged(page) {
        this.fetchEvents(page.year, page.month);
      },
      fetchEvents(year, month) {
        const thisMonth = moment().year(year).month(month - 1);
        const previousMonth = thisMonth.clone().subtract(1, 'month');
        const nextMonth = thisMonth.clone().add(1, 'month');

        this.fetchMonthEvents(thisMonth.year(), thisMonth.month() + 1);
        this.$nextTick(function () {
          this.fetchMonthEvents(previousMonth.year(), previousMonth.month() + 1);
          this.fetchMonthEvents(nextMonth.year(), nextMonth.month() + 1);
        });
      },
      fetchMonthEvents(year, month) {
        return CalendarBean.get(year, month)
          .then(events => {
            if (events) {
              events.forEach((eventItem) => {
                const url = eventItem.customData.event.url;
                this.$set(this.events, `${url}`, eventItem);
              });
            }
          });
      },
      goToEvent(day) {
        if (day.attributes && day.attributes.length > 0) {
          const data = day.attributes[0].customData;
          window.location.href = this.buildEventUrl(data);
        }
      },
      buildEventUrl(data) {
        return data.event.url + "#content"
      }
    }
  };
</script>
