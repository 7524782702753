import $ from "jquery";

const groupToColumns = (refs) => {
  return Object.keys(refs).reduce((result, key) => {
    const ref = refs[key];
    const columnNumber = ref.columnNumber;

    result[columnNumber] = result[columnNumber] || new Column();

    result[columnNumber].push(ref);
    return result
  }, {});
};

const createItems = (refs) => {
  return Object.keys(refs).reduce((result, key) => {
    result[key] = result[key] || {};

    const el = $(refs[key].slice().pop());
    const column = $(el.parents('.gallery-column'));

    result[key] = new Item(el, column);

    return result
  }, {});
};

class Item {
  constructor(el, column) {
    this.el = el;
    this.column = column;
    this.columnNumber = column.attr('data-column');
  }

  get height() {
    return this.el.height()
  }

  get source() {
    return this.el.attr('src');
  }
}

class Column {
  constructor(items) {
    this.items = items || []
  }

  copy() {
    return new Column(this.items.slice())
  }

  get sources() {
    return this.items.map((value) => {
      return value.source
    })
  }

  get height() {
    return this.items.reduce((acc, val) => {
      return acc + val.height;
    }, 0);
  }

  get maxItemHeight() {
    return this.items.reduce((max, val) => {
      return Math.max(max, val.height);
    }, 0);
  }

  get minItemHeight() {
    return this.items.reduce((min, val) => {
      return Math.min(min, val.height);
    }, 0);
  }

  push(item) {
    this.items.push(item)
  }

  pop() {
    return this.items.pop()
  }

}

class Grid {
  constructor(columns) {
    this.columns = columns
  }

  copy() {
    return new Grid(Object.keys(this.columns).reduce((result, name) => {
      result[name] = this.columns[name].copy();
      return result;
    }, {}))
  }

  adjustColumns() {
      const grid = this.copy();

      const {min, max} = grid.computeMinMax();

      if (min && max) {
        min.column.push(max.column.pop());
      }

      return grid;
  }

  computeMinMax() {
    const columnsWithHeight = this.computeHeights();
    return {
      min: columnsWithHeight.shift(),
      max: columnsWithHeight.pop()
    }
  }

  diff() {
    const columnsWithHeight = this.computeHeights();
    if (columnsWithHeight) {
      const min = columnsWithHeight.shift();
      const max = columnsWithHeight.pop();
      if (max && min && max.column && min.column) {
        return max.column.height - min.column.height
      }
    }
    return 0;
  }

  computeHeights() {
    return Object.keys(this.columns).map((name) => {
      return {
        column: this.columns[name],
        height: this.columns[name].height
      }
    }).sort((a, b) => {
      return a.height - b.height
    });
  }

  column(id) {
    return this.columns[id];
  }
}

export default class PhotoGalleryGridBean {
  constructor(columns) {
    this.grid = new Grid(columns);
  }

  static create(refs) {
    return new PhotoGalleryGridBean(groupToColumns(createItems(refs)))
  }

  adjustColumns() {
    let currentGrid = this.grid;
    let newGrid = currentGrid.adjustColumns();

    while (currentGrid.diff() > newGrid.diff()) {
      currentGrid = newGrid;
      newGrid = currentGrid.adjustColumns();
    }
    return new PhotoGalleryGridBean(currentGrid.columns);
  }

  get columnImages() {
    const columns = this.grid.columns;
    return Object.keys(columns).reduce(((result, key) => {
      result[key] = columns[key].sources;
      return result;
    }), {})
  }
}
