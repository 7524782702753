<template>
  <div>
    <div class="row">
      <date-select :index="PHOTO_GALLERIES"
                   :on-change="selectDate"></date-select>
    </div>
    <div v-for="(list, date) in galleries" :key="date" class="row animated fadeIn slow mt-3">
      <div class="col-12">
        <transition name="fade">
          <photo-gallery-list v-if="selected === date" :galleries="list">
          </photo-gallery-list>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import DateSelect from '../DateSelect'
  import PhotoGalleryList from './PhotoGalleryList'

  import PhotoGalleriesBean from './PhotoGalleriesBean'

  const photoGalleriesBean = new PhotoGalleriesBean();

  export default {
    data() {
      return {
        selected: '',
        galleries: {}
      }
    },
    methods: {
      selectDate(date) {
        this.selected = date;
        photoGalleriesBean.fetch(date).then(galleries => {
          this.$set(this.galleries, date, galleries);
        })
      }
    },
    components: {
      DateSelect,
      PhotoGalleryList
    }
  }
</script>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
