<template>
  <div class="mb-5">
    <div class="row d-flex align-content-center">
      <a :href="gallery.url" class="h4 event-title font-weight-bold">
        {{ gallery.title }}
      </a>
      <span class="badge badge-pill badge-secondary p-2 m-1 ml-auto align-self-center">
        {{ parseDate(gallery.date) | moment('D. M.') }}
      </span>
    </div>

    <div class="row justify-content-start text-left">
      <div class="col-md-6">
        <ul class="list-group list-group-flush w-100">
          <li v-if="gallery.author" class="list-group-item">
            <strong>Autor</strong>: {{ gallery.author }}
          </li>
        </ul>
      </div>
    </div>

    <photo-gallery-grid :title="gallery.title" :photos="gallery.photos">
    </photo-gallery-grid>
  </div>
</template>

<script>
  import PhotoGalleryGrid from './PhotoGalleryGrid'
  import moment from 'moment'

  export default {
    props: {
      gallery: {
        type: Object,
        required: true
      }
    },
    methods: {
      parseDate(str) {
        return moment(str, 'YYYY-MM-DD')
      }
    },
    components: {
      PhotoGalleryGrid
    }
  }
</script>
