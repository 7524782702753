<template>
  <div :class="classes" class="row d-flex align-items-center event p-1 m-2 animated fadeInUp fast w-100">

    <event-image :title="event.title"
                 :url="event.image"
                 :side="side"></event-image>

    <event-content :title="event.title"
                   :annotation="event.annotation"
                   :url="event.url"
                   :category="event.category"
                   :start="event.start"
                   :canceled="!!event.canceled"
                   :side="side"></event-content>

  </div>
</template>

<script>
  import EventImage from './EventImage'
  import EventContent from './EventContent'

  export default {
    props: {
      event: {
        type: Object,
        required: true
      },
      side: {
        type: String,
        required: true
      }
    },
    components: {
      EventImage,
      EventContent
    },
    computed: {
      classes() {
        return this.side === 'left' ?
          ['flex-row'] :
          ['flex-row-reverse']
      }
    }
  }
</script>
