import 'array-flat-polyfill';

import Vue from 'vue'
import VueMoment from 'vue-moment'
import VCalendar from 'v-calendar'
import VueAsyncData from 'vue-async-data-2'
import Calendar from './components/Calendar'
import SearchDialog from './components/SeachDialog'
import Program from './components/events/program/Program'
import FilmClub from './components/events/program/FilmClub';
import PhotoGalleries from './components/photos/PhotoGalleries'
import PhotoGalleryGrid from './components/photos/PhotoGalleryGrid'
import TopNEvents from './components/events/topn/TopNEvents';
import NextEvent from './components/events/topn/NextEvent';
import Reservation from './components/reservation/Reservation';
import ReservationMailto from './components/reservation/ReservationMailto';

import { TabsPlugin, CardPlugin, ListGroupPlugin } from 'bootstrap-vue';

import moment from 'moment';
import locale from 'moment/locale/cs';

/**
 * Polyfills
 */
import Promise from 'es6-promise'; Promise.polyfill();
import 'mdn-polyfills/Object.assign';
import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/String.prototype.includes';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.use(ListGroupPlugin);

Vue.use(VCalendar, {
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L',
  },
  locale: 'cs-CZ'
});

Vue.use(VueMoment, {
  moment
});

Vue.mixin({
  data: () => {
    return {
      BASE_URL: BASE_URL,
      EVENT_CALENDAR: EVENT_CALENDAR,
      PHOTO_GALLERIES: PHOTO_GALLERIES
    }
  }
});

Sentry.init({
  dsn: 'https://df4168c585c445c5a137856cd9b9f0e8@sentry.io/1766208',
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
});

new Vue({
  el: '#page-container',
  components: {
    Calendar,
    NextEvent,

    SearchDialog,

    TopNEvents,

    Program,

    PhotoGalleries,
    PhotoGalleryGrid,

    FilmClub,
    Reservation,
    ReservationMailto,
  }
});
