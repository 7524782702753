<template>
  <div>
    <div class="row animated fadeIn slow">
      <transition name="fade">
        <event-list :events="events || []">
        </event-list>
      </transition>
    </div>
</div>
</template>

<script>
  import { AsyncDataMixin } from 'vue-async-data-2';

  import EventList from '../program/EventList';
  import FilmClubBean from './FilmClubBean';

  const filmClubBean = new FilmClubBean();

  export default {
    asyncData: {
      events() {
        return filmClubBean.fetch()
      }
    },
    mixins: [AsyncDataMixin],
    components: {
      EventList
    }
  }
</script>
