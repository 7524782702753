<template>
  <div class="col-md-6 col-sm-12">
    <div :class="classes" class="img-wrap justify-content-sm-center">
      <img v-if="url" :src="url" :alt="title" :title="title"
           v-g-tag="'img-event'"
           class="img-fluid img-thumbnail">
      <img v-else :src="fallbackUrl" :alt="title" :title="title"
           class="img-fluid">
    </div>
  </div>
</template>

<script>
  import gTag from '../ImgGTag';

  const DEFAULT_IMAGE = 'assets/img/theatre.png';

  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      url: {
        type: String,
        required: true
      },
      side: {
        type: String,
        required: true
      }
    },
    computed: {
      fallbackUrl() {
          return `${BASE_URL}/${DEFAULT_IMAGE}`
      },
      classes() {
        return this.side === 'left' ?
          ['justify-content-md-start'] :
          ['justify-content-md-end']
      }
    },
    directives: {
        gTag
    }
  }
</script>
