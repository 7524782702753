export default class Cache {

  constructor() {
    this.cache = {};
  }

  get(key, lambda) {
    if (!this.cache[key]) {
      this.cache[key] = lambda()
    }
    return this.cache[key];
  }

}
