import moment from 'moment';

export default class EventDate {

  constructor(date) {
    this.date = date ? date : new Date();
  }

  static fromDate(dateInput) {
    const date = new moment(dateInput.date, "YYYY-MM-DD HH:mm:ss Z");
    const time = dateInput.time ? new moment(dateInput.time, "HH:mm") : new moment("00:00", "HH:mm");
    return new EventDate(new Date(date.year(), date.month(), date.date(), time.hour(), time.minute()));
  }

  static fromSeconds(secs) {
    const date = new Date(secs * 1000);
    return new EventDate(date);
  }

  get year() {
    return this.date.getFullYear();
  }

  get month() {
    return this.date.getMonth() + 1
  }

  toDate() {
    return this.date
  }
}

export const MONTH_LABELS = {
  1: "Leden",
  2: "Únor",
  3: "Březen",
  4: "Duben",
  5: "Květen",
  6: "Červen",
  7: "Červenec",
  8: "Srpen",
  9: "Září",
  10: "Říjen",
  11: "Listopad",
  12: "Prosinec"
};
