<template>
  <div class="w-100">
    <photo-gallery v-for="(gallery, index) in galleries"
                   :gallery="gallery"
                   :key="index">
    </photo-gallery>
  </div>
</template>

<script>
  import PhotoGallery from "./PhotoGallery";

  export default {
    props: {
      galleries: {
        type: Array,
        required: true
      }
    },
    components: {
      PhotoGallery
    }
  }
</script>
