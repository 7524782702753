<template>
  <div>
    <div class="container timeline">
      <div class="row mb-4">
        <div class="col-lg-8 mx-auto text-center">
          <h2 class="section-heading text-white display-3">
            {{ title }}
          </h2>
          <a href="#events-anchor" id="events-anchor">
            <i class="h2 fas fa-chevron-down text-white"></i>
          </a>
        </div>
      </div>
      <div class="row">
        <event-item v-for="(event, index) in events"
                    :key="index"
                    :event="event"
                    :side="(index % 2) === 0 ? 'left' : 'right'">
        </event-item>
      </div>
      <div class="row text-center">
        <div class="col-12 pt-5 pb-3 mb-5">
          <a @click.prevent.stop="loadMore" v-if="!done" class="btn btn-light btn-md sr-button animated fadeInUp slower">
              Načíst další ...
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNEventsBean from './TopNEventsBean'
  import EventItem from '../EventItem';
  import EventDate from "../../../utils/EventDate";

  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      n: {
        type: Number,
        default: 10
      }
    },
    data() {
      return {
        events: {},
        items: this.n
      }
    },
    created() {
      this.refresh();
    },
    computed: {
        done() {
            return this.items > Object.keys(this.events).length + this.n;
        }
    },
    methods: {
      refresh() {
        return new TopNEventsBean(new EventDate()).fetch(this.items)
          .then(events => {
            return events.reduce((result, val, index) => {
              if (!result[index]) {
                this.$set(result, index, val);
              }
              return result;
            }, this.events)
          });
      },
      loadMore() {
        this.items = this.items + this.n;
        this.refresh();
      }
    },
    components: {
      EventItem
    }
  }
</script>

<style scoped>
  .done {
    background-color: #FFFFFF88;
    cursor: initial !important;
  }
  .done:hover {
    background-color: #FFFFFF88;
  }
</style>
