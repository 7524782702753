<template>
  <div class="row mt-4">
    <div class="col-12">
      <event-item v-for="(event, index) in events"
                  :key="index"
                  :event="event"
                  :side="(index % 2) === 0 ? 'left' : 'right'">
      </event-item>
    </div>
  </div>
</template>

<script>
  import EventItem from '../EventItem'

  export default {
    props: {
      events: {
        type: Array,
        required: true
      }
    },
    components: {
      EventItem
    }
  }
</script>
