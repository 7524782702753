import client from '../../../clients/eventsClient';
import EventDate from "../../../utils/EventDate";

const EVENTS = EVENT_CALENDAR.sort((a, b) => {
  return new Date(a.year, a.month - 1) - new Date(b.year, b.month - 1)
}).reverse();

const eventsByDate = (a, b) => {
  return EventDate.fromDate(a.start).toDate() - EventDate.fromDate(b.start).toDate()
};
export default class TopNEventsBean {
  constructor(eventDate) {
    this.eventDate = eventDate;
    this.futureEventMonths = EVENTS.filter((item) => {
      return new Date(item.year, item.month) - new Date(eventDate.year, eventDate.month) >= 0
    });
  }

  async fetch(n) {
    const future = this.futureEventMonths;
    let result = [];
    while (result.length < n && future.length) {
      const futureEvent = future.pop();
      const events = await client.get(futureEvent.year, futureEvent.month);
      result = result.concat(events
        .filter((event) => {
          const startTime = EventDate.fromDate(event.start).toDate();
          return startTime > this.eventDate.toDate()
        })
        .filter((event) => {
          return !event.canceled;
        }));
    }
    return result.sort(eventsByDate).slice(0, n);
  }

}
