<template>
  <div class="col-md-6 col-sm-12">
    <div :class="classes" class="text-content mt-3">

      <div>
        <div class="h4">
          <span v-if="canceled" class="badge badge-pill badge-warning">
            Akce zrušena
          </span>
        </div>
        <a :href="eventUrl" class="event-title pt-2 h4 font-weight-bold">
          {{ title }}
        </a>
      </div>

      <div class="event-section-text">
        <p class="event-text pt-3">
          <span v-if="start.time" style="text-transform: capitalize">
            {{ startDate | moment('dddd D. M. H:mm') }}
          </span>
          <span v-else>
            {{ startDate | moment('dddd D. M.') }}
          </span>
          <span v-if="category" class="badge badge-pill badge-secondary p-2 pl-3 pr-3 m-2">
            {{ category }}
          </span>
        </p>
      </div>
      <div class="event-section-text">
        <p v-html="annotation" class="event-text">

        </p>
      </div>
      <div class="event-section-btn pt-3 pb-3">
        <a class="btn btn-light btn-md sr-button"
           :href="eventUrl">
          Více zde
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import EventDate from "../../utils/EventDate";

  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      start: {
        type: Object,
        required: true
      },
      category: {
        type: String,
        required: true
      },
      annotation: {
        type: String,
        required: true
      },
      url: {
        type: String,
        required: true
      },
      side: {
        type: String,
        required: true
      },
      canceled: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      eventUrl() {
        return this.url + '#content';
      },
      startDate() {
        return EventDate.fromDate(this.start).toDate();
      },
      classes() {
        return this.side === 'left' ?
          ['text-align-left'] :
          ['text-align-right']
      }
    }
  }
</script>
