import axios from 'axios';
const URL = 'https://us-central1-msuo-e5cf2.cloudfunctions.net/sendMail';

const makeEventGTags = (data) => {
  gtag('event', 'mail', {
    'event_category' : 'reservation',
    'event_label' : `${data.title}`
  });
};

export default {
  send(data) {
    makeEventGTags(data);

    return axios.post(URL, data)
      .then(response => {
        if (response.status !== 200) {
          throw new Error(JSON.stringify(response));
        }
        return response.data;
      })
  }
}
