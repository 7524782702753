<template>
  <div>
    <div>
      <p><strong>Rychlá volba</strong> následující akce s možností rezervace</p>
      <p>
        <b-list-group horizontal>
          <b-list-group-item v-for="event in nextEvents" v-bind:key="toDate(event.start) | moment('D. M. H:mm')">
            <a href="#" @click.prevent.stop="selectEvent(event)">
              {{ event.event }}
              <small class="badge badge-primary badge-pill">{{ toDate(event.start) | moment('D. M. H:mm') }}</small>
            </a>
          </b-list-group-item>
        </b-list-group>
      </p>
    </div>
    <div class="alert-popup-wrapper">
      <div class="alert-popup">
        <b-alert
          :show="alerts.error.dismissCountDown"
          dismissible
          fade
          variant="danger"
          @dismiss-count-down="(countDown) => alerts.error.dismissCountDown = countDown"
          @dismissed="alerts.error.dismissCountDown=0"
        >
          Chyba při odesílání rezervace.
        </b-alert>
      </div>
      <div class="alert-popup">
        <b-alert
          :show="alerts.success.dismissCountDown"
          dismissible
          fade
          variant="success"
          @dismiss-count-down="(countDown) => alerts.success.dismissCountDown = countDown"
          @dismissed="alerts.success.dismissCountDown=0"
        >
          Rezervace {{form.event}} na jméno {{form.name}} odeslána. <strong>Rezervace bude platná po emailovém potvrzení na Vámi zadanou adresu</strong>
        </b-alert>
      </div>
      <div class="alert-popup">
        <b-alert
          :show="alerts.progress.dismissCountDown"
          dismissible
          fade
          variant="info"
          @dismiss-count-down="(countDown) => alerts.progress.dismissCountDown = countDown"
          @dismissed="alerts.progress.dismissCountDown=0"
        >
          Odesílám ...
        </b-alert>
      </div>
    </div>

    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group
        id="input-group-event"
        label="Hledat:"
        label-for="input-event"
        description="Dejte nám vědet o kterou událost máte zájem.">
        <typeahead
          ref="search"
          :data="events"
          :serializer="s => s.event"
          placeholder="Jméno události ..."
          @hit="selectEvent($event)"
          required
          inputClass="">
          <template slot="suggestion" slot-scope="{ data, htmlText }">
            <span v-html="htmlText"></span>
            &nbsp;
            <small class="badge badge-primary badge-pill">{{ toDate(data.start) | moment('D. M. H:mm') }}</small>
          </template>
        </typeahead>
        <b-form-invalid-feedback :state="$v.form.item.$dirty ? !$v.form.item.$error : null">
          Vyberte událost
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-name"
        label="Jméno:"
        label-for="input-email"
        description="Jméno, na které bude rezervace">
        <b-form-input
          id="input-name"
          v-model="$v.form.name.$model"
          type="text"
          required
          placeholder="Jméno ...">
        </b-form-input>
        <b-form-invalid-feedback :state="$v.form.name.$dirty ? !$v.form.name.$error : null">
          Zadejte jméno
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-event-amount"
        label="Počet míst:"
        label-for="input-event-amount"
        description="Kolik vás bude"
        placeholder="Počet ...">
        <b-form-input
          id="input-event-amount"
          v-model="$v.form.amount.$model"
          type="number"
          required>
        </b-form-input>
        <b-form-invalid-feedback :state="$v.form.amount.$dirty ? $v.form.amount.required : null">
          Zadejte počet
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="$v.form.amount.$dirty ? $v.form.amount.minValue : null">
          Zadejte počet
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="$v.form.amount.$dirty ? $v.form.amount.maxValue : null">
          Pro vetší počet rezervací jak 10, nás prosím kontaktujte prostřednictvím kontaktu uvedeného v patičce stránky
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-event-name"
        label="Akce:"
        label-for="input-event-name">
        <b-form-input
          id="input-event-name"
          v-model="$v.form.event.$model"
          type="text"
          readonly
          required>
        </b-form-input>
        <b-form-invalid-feedback :state="$v.form.event.$dirty ? !$v.form.event.$error : null">
          Vyhledejte událost
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-event-date"
        label="Datum:"
        label-for="input-event-date">
        <b-form-input
          id="input-event-date"
          v-model="$v.form.date.$model"
          type="text"
          readonly
          required>
        </b-form-input>
      </b-form-group>

      <div v-if="!$v.form.$invalid && !disabled">
        <strong>Napište nám email na adresu <i class="text-primary">{{mailTo}}</i> v následujícím tvaru nebo klikněte na tlačítko níže pro předvyplnění</strong>

        <b-jumbotron header="" lead="" style="padding: 1em">
          Událost: {{form.event}}<br/>
          Datum: {{form.date}}<br/>
          Množství: {{form.amount}}<br/>
          Jméno: {{form.name}}<br/>
        </b-jumbotron>
      </div>

      <div ref="subject" style="display: none">Rezervace MSUO {{form.event}}</div>

      <div ref="body" style="display: none">Událost: {{form.event}}%0D%0A
Datum: {{form.date}}%0D%0A
Množství: {{form.amount}}%0D%0A
Jméno: {{form.name}}%0D%0A
      </div>

      <b-button
        :disabled="$v.form.$invalid || disabled"
        @click="openEmail"
        variant="primary">Vyplnit email</b-button>
    </b-form>
  </div>
</template>

<script>
  import moment from 'moment';
  import Vue from 'vue';
  import AsyncComputed from 'vue-async-computed';
  import { BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormValidFeedback, BButton, BAlert,
    BListGroup, BListGroupItem, BJumbotron } from 'bootstrap-vue';
  import { validationMixin } from 'vuelidate'
  import { required, minValue, maxValue } from 'vuelidate/lib/validators'
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
  import eventsClient from "../../clients/eventsClient";
  import EventDate from "../../utils/EventDate";

  const eventsByDate = (a, b) => {
    return EventDate.fromDate(a.start).toDate() - EventDate.fromDate(b.start).toDate()
  };

  Vue.use(AsyncComputed);

  export default {
    data() {
      return {
        mailTo: 'malascenausti@gmail.com',
        searchText: null,
        form: {
          item: null,
          event: null,
          amount: 1,
          date: null,
          name: null
        },
        alerts: {
          success: {
            dismissSecs: 15,
            dismissCountDown: 0
          },
          error: {
            dismissSecs: 10,
            dismissCountDown: 0
          },
          progress: {
            dismissSecs: 3,
            dismissCountDown: 0
          }
        },
        disabled: false
      }
    },
    computed: {
      nextEvents() {
        const now = new Date();
        return this.events
          .filter(e => EventDate.fromDate(e.start).toDate() - now > 0)
          .sort(eventsByDate)
          .slice(0, 5);
      },
      subjectText() {
        return this.$refs.subject.innerText
      },
      bodyText() {
        return this.$refs.body.innerHTML
      }
    },
    asyncComputed: {
      events: {
        async get() {
          const threshold = moment().endOf('day');
          const events = await eventsClient.getReservations();
          return events.filter(e => {
            const date = moment(EventDate.fromDate(e.start).toDate());
            return date.isAfter(threshold);
          })
        },
        default() {
          return []
        }
      }
    },
    methods: {
      selectEvent($event) {
        this.$refs.search.inputValue = $event.event;
        this.$v.form.item.$model = $event;
        this.$v.form.event.$model = $event.event;
        this.$v.form.date.$model = this.toDate($event.start).toLocaleString();
      },
      toDate(date) {
        return EventDate.fromDate(date).toDate()
      },
      reset() {
        this.$v.$reset();
        this.$set(this, 'form', {
          item: null,
          event: null,
          amount: 1,
          date: null,
          name: null
        });
        this.disabled = false
      },
      showSuccess() {
        this.alerts.success.dismissCountDown = this.alerts.success.dismissSecs
      },
      showError() {
        this.alerts.error.dismissCountDown = this.alerts.error.dismissSecs
      },
      showProgress() {
        this.alerts.progress.dismissCountDown = this.alerts.progress.dismissSecs
      },
      openEmail() {
        window.location.href = 'mailto:'+this.mailTo+'?subject='+this.subjectText+'&body='+this.bodyText
      }
    },
    validations: {
      form: {
        item: {
          required
        },
        event: {
          required
        },
        amount: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(10)
        },
        date: {
          required
        },
        name: {
          required
        }
      }
    },
    components: {
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BFormValidFeedback,
      BButton,
      BAlert,
      BListGroup,
      BListGroupItem,
      BJumbotron,
      typeahead: VueBootstrapTypeahead
    },
    mixins: [
      validationMixin
    ]
  }
</script>
