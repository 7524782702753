<template>
  <div>
    <div class="row">
      <div class="col-12 py-2">
        <input @keyup.enter="search(term)" v-model.lazy.trim="term" class="form-control" type="text" placeholder="Hledat">
      </div>
    </div>
    <div class="row float-right">
      <div class="col-12">
        <button @click="search(term)" type="button" class="btn btn-secondary my-1">Vyhledat událost</button>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group list-group-flush">
          <li v-for="result in results" class="list-group-item d-flex justify-content-between align-items-center">
            <a :href="result.item.href">
              <span v-html="result.highlight.title || result.item.title"></span>
            </a>
            <span v-html="result.highlight.content || result.item.content">
            </span>
            <span class="badge badge-primary badge-pill">
              {{ toDate(result.item.start) | moment('D. M. YYYY') }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="searchedTerm && !results.length" class="row">
      <div class="col-12">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <span>Žádné události nenalezeny</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import FuseSearch from "./FuseSearch";
  import highlight from "./fuseHighlight";
  import EventDate from "../../utils/EventDate";

  export default {
    data() {
      return {
        term: '',
        results: [],
        searchedTerm: ''
      }
    },
    computed: {
      fuse() {
        return FuseSearch.fetch();
      },
    },
    methods: {
      search(term) {
        this.fuse
          .then((fuse) => fuse.search(term))
          .then((results) => {
            return results
              .filter((item) => item.matches.length)
              .map((item) => {
                highlight(item);
                return item;
              });
          })
          .then((results) => {
            this.$set(this, 'results', results);
            this.$set(this, 'searchedTerm', term);
            gtag('event', 'search_action', {
              'event_category' : 'search',
              'event_label' : `${term}`,
              'value': results.length
            });
          });
      },
      toDate(date) {
        return EventDate.fromDate(date).toDate();
      }
    }
  }
</script>
