import client from '../clients/eventsClient';
import EventDate from "../utils/EventDate";

const regularEventOverrides = {
  highlight : {
    backgroundColor: '#C04F30',
    borderColor: '#8c4126',
    borderWidth: '1px',
    borderStyle: 'solid'
  }
};

const canceledEventOverrides = {
  highlight: {
    backgroundColor: '#BEBEBE',
    borderColor: '#999',
    borderWidth: '1px',
    borderStyle: 'solid'
  }
};

const customData = {
};

const customDataCanceled = {
  labelStyle: {
    'text-decoration': 'line-through'
  }
};

const isPresentInCalendar = (year, month) => {
  return EVENT_CALENDAR.filter((item) => {
    return item.year === year && item.month === month
  }).length > 0
};

class EventFactory {
  static createSingleEvent(event, dates) {
    const overrides = event.canceled ? canceledEventOverrides : regularEventOverrides;
    const customDataOverrides = event.canceled ? customDataCanceled : customData;
    const order = event.canceled ? 0 : 1;
    const calendarEvent = {
      popover: {
        label: event.title,
        slot: 'event',
      },
      contentStyle: {
        color: '#fff'
      },
      customData: {
        ...customDataOverrides,
        event,
        start: dates
      },
      dates,
      order
    };
    return {
      ...calendarEvent,
      ...overrides
    };
  }

  static createLongEvent(event, start, end) {
    const startEvent = EventFactory.createSingleEvent(event, start);
    const dates = [
      {
        start,
        end
      }
    ];
    const longEvent = {
      popover: {
        label: event.title,
        slot: 'event',
      },
      highlight: {
        backgroundColor: 'rgba(192,79,48,0.15)',
        borderColor: 'rgba(64,26,15,0.15)',
        borderWidth: '1px',
        borderStyle: 'solid',
      },
      customData: {
        event,
        start,
        end
      },
      dates,
    };
    if (event.canceled) {
      return startEvent;
    } else {
      return [
        startEvent,
        longEvent
      ]
    }
  }

  static createEvent(event) {
    const startDate = EventDate.fromDate(event.start);
    const endDate = event.end.date ? EventDate.fromDate(event.end) : null;

    return endDate
      ? EventFactory.createLongEvent(event, startDate.toDate(), endDate.toDate())
      : EventFactory.createSingleEvent(event, startDate.toDate())
  }
}

export default class CalendarBean {

  static calendarEvent(event) {
    return EventFactory.createEvent(event)
  }

  static get(year, month) {
    if (isPresentInCalendar(year, month)) {
      return client.get(year, month)
        .then((events) => {
          if (!events) {
            return []
          }
          return events.map(CalendarBean.calendarEvent).flat(1)
        })
    } else {
      return Promise.resolve([])
    }
  }
}
