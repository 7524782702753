import Fuse from 'fuse.js';
import axios from "axios";
import Urls from "../../utils/Urls";

const index = () => {
  return axios.get(Urls.relativeUrl('/search-index.json'))
    .then(value => {
      return value.data;
    })
    .catch(e => {
      console.warn(e);
    });
};

export default class FuseSearch {
  constructor(fuse) {
    this.fuse = fuse;
  }

  search(term) {
    return this.fuse.search(term);
  }

  static fetch() {
    const options = {
      shouldSort: true,
      // tokenize: false,
      // matchAllTokens: true,
      includeMatches: true,
      threshold: 0.1,
      // location: 0,
      distance: 100000,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: [
        "title"
      ]
    };

    return index().then((i) => new FuseSearch(new Fuse(i, options)));
  }
}
