import client from '../../../clients/eventsClient';
import EventDate from '../../../utils/EventDate';

const eventsByDate = (a, b) => {
  return EventDate.fromDate(a.start).toDate() - EventDate.fromDate(b.start).toDate()
};

export default class ProgramBean {
  fetch(key) {
    const parts = key.split("/");
    const date = {
      month: parts[1],
      year: parts[0]
    };
    return client.get(date.year, date.month)
      .then((events) => events.sort(eventsByDate))
  }
}
