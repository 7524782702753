// export default function highlight(term, html) {
//   const query = new RegExp("(" + term + ")", "gim");
//   const result = html.replace(query, "<mark>$1</mark>");
//   return result;
// }

const fuseHighlight = (resultItem) => {
  resultItem.highlight = resultItem.highlight || {};

  resultItem.matches.forEach((matchItem) => {
    const text = resultItem.item[matchItem.key];
    const result = [];
    const matches = [].concat(matchItem.indices);
    let pair = matches.shift();

    for (let i = 0; i < text.length; i++) {
      const char = text.charAt(i);
      if (pair && i === pair[0]) {
        result.push('<mark>')
      }
      result.push(char);
      if (pair && i === pair[1]) {
        result.push('</mark>');
        pair = matches.shift()
      }
    }
    resultItem.highlight[matchItem.key] = result.join('');

    if(resultItem.children && resultItem.children.length > 0) {
      resultItem.children.forEach((child) => {
        fuseHighlight(child);
      });
    }
  });
};

export default fuseHighlight;
