import axios from 'axios';
import Cache from '../utils/Cache';
import Urls from '../utils/Urls';

const baseUrl = Urls.relativeUrl('/data/events');
const filmClubUrl = Urls.relativeUrl('/program/film-club.json');
const reservationsUrl = Urls.relativeUrl('/reservations.json');

const buildUrl = (year, month) => {
  return [
    baseUrl,
    year,
    month,
    '/events.json'
  ].join('/');
};

const cache = new Cache();

const makeEventGTags = (year, month) => {
  const now = new Date();
  const thisYear = now.getFullYear();
  const thisMonth = now.getMonth() + 1;
  const history = 12 * (year - thisYear) + (month - thisMonth);

  gtag('event', 'history', {
    'event_category' : 'eventProgram',
    'event_label' : `${history} months`
  });
  gtag('event', 'ajax', {
    'event_category' : 'eventsClient',
    'event_label' : `get(${year}.${month})`
  });
};

export default {
  get(year, month) {
    makeEventGTags(year, month);

    return cache.get(`${year}.${month}`, () => {
      return axios.get(buildUrl(year, month))
        .then(value => {
          return value.data;
        })
        .then(value => {
          return value.map((item) => {
            item.url = Urls.relativeUrl(item.url);
            return item;
          })
        })
    });
  },

  getFilmClub() {
    gtag('event', 'ajax', {
      'event_category' : 'eventsClient',
      'event_label' : `getFilmClub()`
    });
    return axios.get(filmClubUrl)
      .then(value => {
        return value.data;
      })
      .then(value => {
        return value.map((item) => {
          item.url = Urls.relativeUrl(item.url);
          return item;
        })
      })
      .catch(e => {
        console.warn(e);
      })
  },

  getReservations() {
    gtag('event', 'ajax', {
      'event_category' : 'eventsClient',
      'event_label' : `getReservations()`
    });
    return axios.get(reservationsUrl)
      .then(value => {
        return value.data;
      })
      .catch(e => {
        console.warn(e);
      })
  }
}
