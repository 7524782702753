<template>
  <div>
    <div v-if="opened" class="modal-backdrop fade" :class="showStyle"></div>

    <div class="search-modal modal fade" :class="showStyle" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">
              Vyhledávání
            </span>
            <button @click="close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <search-container></search-container>
          </div>
          <div class="modal-footer">
            <button @click="close" type="button" class="btn btn-primary">Zavřít</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import SearchContainer from "./search/SearchContainer";
    import { mixin as clickaway } from 'vue-clickaway';

    export default {
        data() {
            return {
                opened: false
            }
        },
        computed: {
            showStyle() {
                return {
                    show: this.opened
                }
            }
        },
        created() {
            document.addEventListener('keyup', (e) => {
                if (e.keyCode === 27) {
                    this.close();
                }
            });
            this.$root.$on("openSearchDialog", () => {
                this.$nextTick(() => {
                    this.opened = true;
                });
            })
        },
        methods: {
            close() {
                this.opened = false
            },
        },
        components: {
            SearchContainer
        },
        mixins: [ clickaway ]
    };
</script>

<style lang="scss">
  .modal {
    display: none;
  }
  .modal.show {
    display: block;
  }

  .search-modal {
    .modal{
      display: block !important; /* I added this to see the modal, you don't need this */
    }
    /* Important part */
    .modal-dialog {
      overflow-y: initial !important
    }
    .modal-body {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }
  }
</style>
