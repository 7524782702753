<template>
  <div v-images-loaded="loaded" v-gallery class="d-flex no-gutters popup-gallery">
    <div v-for="(column, index) in columns" :key="index"
         :data-column="index"
         class="d-flex flex-column gallery-column">
      <div v-for="photo in column" class="p-1">
        <a class="gallery-box" :href="photo">
          <img class="img-fluid"
               :ref="photo"
               :alt="title"
               :src="photo"
               v-g-tag="'img-gallery'">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';
  import imagesLoaded from 'vue-images-loaded'
  import PhotoGalleryGridBean from './PhotoGalleryGridBean'
  import gTag from '../ImgGTag'

  const COLUMNS_NUM = 3;

  const splitColumns = (photos) => {
      const result = {};
      if (photos.length) {
        let i = 0;
        do {
          const photo = photos.pop();
          const index = 'c' + (i % COLUMNS_NUM);
          (result[index] || (result[index] = [])).push(photo);
          i++
        } while (photos.length);
      }
      return result;
  };

  const gallery = (el) => {
    $(el).magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
      }
    })
  };

  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      photos: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        columns: splitColumns(this.photos.slice()),
        gridAdjusted: false
      }
    },
    methods: {
      loaded() {
        if (!this.gridAdjusted) {
          this.gridAdjusted = true;

          const grid = PhotoGalleryGridBean.create(this.$refs).adjustColumns();
          const columns = grid.columnImages;

          this.columns = columns
        }
      }
    },
    directives: {
      imagesLoaded,
      gallery,
      gTag
    }
  }
</script>
