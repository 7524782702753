import client from '../../clients/galleriesClient'

export default class PhotoGalleriesBean {
  fetch(key) {
    const parts = key.split("/");
    const date = {
      month: parts[1],
      year: parts[0]
    };
    return client.get(date.year, date.month)
  }

}
