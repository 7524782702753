<template>
  <div>
    <div class="row">
      <date-select :index="EVENT_CALENDAR"
                          :on-change="selectDate"></date-select>
    </div>
    <div v-for="(list, date) in events" class="row animated fadeIn slow">
      <div class="col-12">
        <transition name="fade">
          <event-list v-if="selected === date" :events="list">
          </event-list>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import EventList from '../../events/program/EventList';
  import DateSelect from '../../DateSelect';

  import ProgramBean from './ProgramBean'

  const programBean = new ProgramBean();

  export default {
    data() {
      return {
        selected: '',
        events: {}
      }
    },
    methods: {
      selectDate(date) {
        this.selected = date;
        programBean.fetch(date).then(events => {
          this.events = {...this.events,
            [date]: events
          };
        })
      }
    },
    components: {
      DateSelect,
      EventList
    }
  }
</script>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
    #program {
      transition: opacity 1s;
    }
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    #program {
      opacity: 0;
    }
  }
</style>
