<template>
  <div v-if="event">
    <h2 class="font-weight-bold mt-3">
      <a :href="toUrl(event.url)" class="text-white">
        {{ event.title }}
      </a>
    </h2>
    <span class="h3" style="text-transform: capitalize">
      {{ eventStartDate | moment('dddd D. M. H:mm') }}
    </span>
    <div v-html="event.annotation" class="mt-3">
    </div>
    <div class="mt-3">
      <a :href="toUrl(event.url)" class="btn btn-light btn-md sr-button">
        Více zde
      </a>
    </div>
  </div>
</template>

<script>
  import { AsyncDataMixin } from 'vue-async-data-2';
  import EventDate from '../../../utils/EventDate';
  import TopNEventsBean from './TopNEventsBean';

  const topNEventsBean = new TopNEventsBean(new EventDate());

  export default {
    asyncData: {
      event() {
        return topNEventsBean.fetch(1).then((events) => {
          return events.pop()
        });
      }
    },
    computed: {
      eventStartDate() {
          return EventDate.fromDate(this.event.start).toDate()
      }
    },
    methods: {
      toUrl(url) {
        return url + '#content';
      }
    },
    mixins: [AsyncDataMixin]
  }
</script>
