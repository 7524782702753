import client from '../../../clients/eventsClient';
import EventDate from '../../../utils/EventDate';

const eventsByDate = (a, b) => {
  return EventDate.fromDate(a.start).toDate() - EventDate.fromDate(b.start).toDate()
};

export default class FilmClubBean {
  fetch() {
    return client.getFilmClub()
      .then((events) => {
        debugger;
        return events.filter((event) => {
          const startTime = EventDate.fromDate(event.start).toDate();
          return startTime >= new Date()
        }).sort(eventsByDate)
      })
  }
}
