import axios from 'axios';
import Cache from '../utils/Cache';
import Urls from '../utils/Urls';

const baseUrl = Urls.relativeUrl('/data/galleries');

const buildUrl = (year, month) => {
  return [
    baseUrl,
    year,
    month,
    'galleries.json'
  ].join('/');
};

const cache = new Cache();

const makeEventGTags = (year, month) => {
  const now = new Date();
  const thisYear = now.getFullYear();
  const thisMonth = now.getMonth() + 1;
  const history = 12 * (year - thisYear) + (month - thisMonth);

  gtag('event', 'history', {
    'event_category' : 'gallery',
    'event_label' : `${history} months`
  });
  gtag('event', 'ajax', {
    'event_category' : 'galleriesClient',
    'event_label' : `get(${year}.${month})`
  });
};

export default {
  get(year, month) {
    makeEventGTags(year, month);
    return cache.get(`${year}.${month}`, () => {
      return axios.get(buildUrl(year, month))
        .then(value => {
          return value.data;
        })
        .catch(e => {
          console.warn(e);
        })
    });
  }
}
